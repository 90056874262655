import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  newOrder,
  setpaymentUpdateOrder,
  updateOrder,
} from "../../redux/order/OrderSlice";
import {
  clearCart,
  netpayableUpdate,
  walletamountUpdate,
} from "../../redux/cart/CartSlice";
import {
  clientUpdate,
  paymentTypeChange,
  walletTransitionPost,
  walletUpdate,
  walletUpdateAfterOrder,
  walletUseUpdate,
} from "../../redux/athentication/Athentication";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { Baseurl3 } from "../../config/BaseUrl";

const RightSideCheckOut = () => {
  const {
    CartItems,
    cartTotalAmount,
    ProductTotal,
    cartCouponDiscount,
    logisticsAmount,
    netPayable,
    cartTotalMrp,
    CouponDiscountAmount,
    cartTotalPrice,
    updateNetPayable,
    usedWalletAmount,
  } = useSelector((state) => state.Cart);
  const {
    name,
    currentAddress,
    clientid,
    email,
    loginData,
    walletUse,
    paymentType,
  } = useSelector((state) => state.Athentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [closeOrderStatus, setCloseOrderStatus] = useState("");
  useEffect(() => {
    setTimeout(() => {
      const getStatus = async () => {
        const url = `${Baseurl3}/api/v1/closeorder/all`;
        const resp = await axios.get(url);
        if (resp.data.success) {
          setCloseOrderStatus(resp.data.orderStatus);
        }
      };
      getStatus();
    }, 1000);
  }, []);

  const [mainerrormassage, setMainErrormassage] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [cartamountDetails, setCartamountDetails] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);

  useEffect(() => {
    let cartTotalAmountN = 0;
    let totalAmount = 0;
    let netPayableN = 0;
    let cartTotalMrpN = 0;
    let cartTotalPriceN = 0;
    let logisticsAmountN = 40;
    const totalitem = CartItems;

    for (let i = 0; i < totalitem.length; i++) {
      cartTotalAmountN =
        Number(cartTotalAmountN) + Number(CartItems[i].TotalAmount);
      cartTotalMrpN = Number(cartTotalMrpN) + Number(CartItems[i].TotalMrp);
      cartTotalPriceN =
        Number(cartTotalPriceN) + Number(CartItems[i].TotalPrice);
    }

    if (cartTotalAmountN >= 500) {
      logisticsAmountN = 0;
    } else {
      logisticsAmountN = 40;
    }
    totalAmount =
      Number(cartTotalAmountN) +
      Number(logisticsAmountN) -
      Number(CouponDiscountAmount);
    netPayableN = Number(totalAmount) - Number(usedWalletAmount);

    const cartAmountsForm = {
      cartTotalAmount: Number(cartTotalAmountN),
      netPayable: Number(netPayableN),
      cartTotalMrp: Number(cartTotalMrpN),
      cartTotalPrice: Number(cartTotalPriceN),
      logisticsAmount: Number(logisticsAmountN),
      totalAmount: Number(totalAmount),
    };
    setCartamountDetails(cartAmountsForm);
  }, [CartItems]);

  useEffect(() => {
    if (CartItems.length === 0) {
      navigate("/");
    }
  }, []);

  const cashOnDeliveryHandleSubmit = async (e) => {
    const url = `${Baseurl3}/api/v1/closeorder/all`;
    const resp = await axios.get(url);
    if (resp.data.success) {
      if (resp?.data?.orderStatus?.status === false) {
        if (!btnLoading) {
          setbtnLoading(true);
          const addressString = `${
            currentAddress.HNo +
            "," +
            currentAddress.StreetDet +
            "," +
            currentAddress.AName +
            "," +
            currentAddress.LandMark +
            "," +
            currentAddress.City +
            "," +
            currentAddress.State +
            "," +
            currentAddress.Type +
            "," +
            currentAddress.Pincode
          }`;

          let grandasTotal = 0;
          if (!walletUse) {
            grandasTotal = cartamountDetails.netPayable;
          } else {
            grandasTotal = updateNetPayable;
          }
          var date = new Date();
          let hour = date.getHours();
          if (hour >= 10 && hour <= 17) {
            // Checking if the time is between 10 AM and 5 PM
            date.setHours(date.getHours() + 3); // Add 3 hours to the current time
          } else if (hour > 17) {
            // Checking if the time is beyond 5 PM
            date.setDate(date.getDate() + 1); // Move to the next day
            date.setHours(10); // Set the time to 10:00 AM
            date.setMinutes(0); // Set the minutes to 00
          } else {
            // Optional: If the hour is before 10 AM, handle this case if needed
            date.setHours(10); // You could set the time to 10:00 AM on the same day
            date.setMinutes(0); // Set the minutes to 00
          }
          let deliveryCost = 0;
          if (Number(cartamountDetails.totalAmount) >= 299) {
            deliveryCost = 0;
          } else {
            deliveryCost = logisticsAmount;
          }
          if (currentAddress !== "" && cartamountDetails.totalAmount > 0) {
            const orderData = {
              ClientId: clientid,
              ClientName: name,
              TotalAmount: Number(cartamountDetails.totalAmount),
              Email: email,
              DeliveryCharge: deliveryCost,
              wallet: usedWalletAmount,
              GrandTotal:
                Number(cartamountDetails.totalAmount) -
                Number(usedWalletAmount),
              Address: addressString,
              AreaName: currentAddress.StreetDet,
              Mobile: loginData.Mobile,
              PaymentStatus: "COD",
              PaymentMode: "COD",
              ExpectedDelDate: date,
              ProductCount: CartItems.length,
              Saving:
                cartamountDetails.cartTotalMrp +
                CouponDiscountAmount -
                cartamountDetails.cartTotalPrice,
              Cashback: "0",
              CurrentStatus: "Not Paid",
              TxnId: "NA",
              couponDetails: {
                // coupon: cartCoupon.CouponName,
                // couponCode: cartCoupon.Title,
                // couponDis: CouponDiscountAmount,
                // CouponTitle: cartCoupon.Title,
                // CouponDescription: cartCoupon.Description,
              },
              OrderProducts: CartItems,
            };
            const order = await dispatch(newOrder(orderData));
            if (!order) {
            } else {
              if (order.payload.success) {
                dispatch(clearCart());
                const orderId = order.payload.order;
                const userUpdateForm = {
                  claintid: loginData._id,
                  WalletBalance: loginData.WalletBalance - usedWalletAmount,
                };

                const client = await dispatch(clientUpdate(userUpdateForm));

                dispatch(
                  walletUpdateAfterOrder(
                    loginData.WalletBalance - usedWalletAmount
                  )
                );

                const walletForm = {
                  ClientId: loginData._id,
                  previousAmount: loginData.WalletBalance,
                  availableAmount: loginData.WalletBalance - usedWalletAmount,
                  transactionAmount: usedWalletAmount * -1,
                  orderId: orderId._id,
                  Type: "Wallet debit on New Order",
                  ClientName: loginData.Name,
                  Mobile: loginData.Mobile,
                };

                dispatch(walletTransitionPost(walletForm));

                const sendOptthowwp = async () => {
                  // const name = name;
                  const mobile = loginData.Mobile;
                  let data = JSON.stringify({
                    countryCode: "+91",
                    phoneNumber: mobile,
                    callbackData: "some text here",
                    type: "Template",
                    template: {
                      name: "order_placed_prepaid",
                      languageCode: "en",
                      bodyValues: [name],
                    },
                  });

                  let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: "https://api.interakt.ai/v1/public/message/",
                    headers: {
                      Authorization:
                        "Basic djlWdldPY1habHlBbEtoZkFFUl8zVWdQTDAycmJqWl9BWHd4dDF4eHVjMDo=",
                      "Content-Type": "application/json",
                    },
                    data: data,
                  };

                  axios
                    .request(config)
                    .then((response) => {
                      // console.log(JSON.stringify(response.data));
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                };
                sendOptthowwp();

                toast.success("ORDER PLACED SUCCESSFULL", {
                  position: "top-center",
                  autoClose: 500,
                });
                navigate("/order-success");
              } else {
                toast.error("ORDER NOT PLACED SUCCESSFULL", {
                  position: "top-center",
                  autoClose: 2000,
                });
                setbtnLoading(false);
              }
            }
          } else if (cartamountDetails.totalAmount === 0) {
            toast.error("Please Check The Cart Products", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Please Add Address", {
              position: "top-center",
              autoClose: 2000,
            });
          }
        }
      } else {
        alert(resp?.data?.orderStatus?.statusText);
        navigate("/");
      }
    }
  };
  const payTmPayHandleSubmit = async (e) => {
    const url = `${Baseurl3}/api/v1/closeorder/all`;
    const resp = await axios.get(url);
    if (resp.data.success) {
      if (resp?.data?.orderStatus?.status === false) {
        if (!btnLoading) {
          setbtnLoading(true);
          const addressString = `${
            currentAddress.HNo +
            "," +
            currentAddress.StreetDet +
            "," +
            currentAddress.AName +
            "," +
            currentAddress.LandMark +
            "," +
            currentAddress.City +
            "," +
            currentAddress.State +
            "," +
            currentAddress.Type +
            "," +
            currentAddress.Pincode
          }`;

          let grandasTotal = 0;
          if (!walletUse) {
            grandasTotal = cartamountDetails.netPayable;
          } else {
            grandasTotal = updateNetPayable;
          }
          var date = new Date();
          date.setDate(date.getDate() + 1);
          if (currentAddress !== "") {
            const orderData = {
              ClientId: clientid,
              ClientName: name,
              TotalAmount: Number(cartamountDetails.totalAmount),
              Email: email,
              DeliveryCharge: cartamountDetails.logisticsAmount,
              wallet: usedWalletAmount,
              GrandTotal:
                Number(cartamountDetails.totalAmount) -
                Number(usedWalletAmount),
              Address: addressString,
              AreaName: currentAddress.StreetDet,
              Mobile: loginData.Mobile,
              PaymentStatus: "COD",
              PaymentMode: "COD",
              TxnId: "NA",
              CurrentStatus: "Not Paid",
              ExpectedDelDate: date,
              ProductCount: CartItems.length,
              Saving:
                cartamountDetails.cartTotalMrp +
                CouponDiscountAmount -
                cartamountDetails.cartTotalPrice,
              Cashback: "0",

              couponDetails: {
                // coupon: cartCoupon.CouponName,
                // couponCode: cartCoupon.Title,
                // couponDis: CouponDiscountAmount,
                // CouponTitle: cartCoupon.Title,
                // CouponDescription: cartCoupon.Description,
              },
              OrderProducts: CartItems,
            };

            const order = await dispatch(newOrder(orderData));

            if (!order) {
            } else {
              if (order.payload.success) {
                dispatch(paymentTypeChange("CashOnDelivery"));

                const orderId = order.payload.order;
                const orderCurrent = order.payload.order;
                const tranId = order.payload.respo;
                dispatch(setpaymentUpdateOrder(order.payload));
                const userUpdateForm = {
                  claintid: loginData._id,
                  WalletBalance: loginData.WalletBalance - usedWalletAmount,
                };

                const client = await dispatch(clientUpdate(userUpdateForm));

                dispatch(
                  walletUpdateAfterOrder(
                    loginData.WalletBalance - usedWalletAmount
                  )
                );

                const walletForm = {
                  ClientId: loginData._id,
                  previousAmount: loginData.WalletBalance,
                  availableAmount: loginData.WalletBalance - usedWalletAmount,
                  transactionAmount: usedWalletAmount * -1,
                  orderId: orderId._id,
                  Type: "Wallet debit on New Order",
                  ClientName: loginData.Name,
                  Mobile: loginData.Mobile,
                };

                dispatch(walletTransitionPost(walletForm));

                var config = {
                  root: "",
                  style: {
                    bodyBackgroundColor: "#fafafb",
                    bodyColor: "",
                    themeBackgroundColor: "#0FB8C9",
                    themeColor: "#ffffff",
                    headerBackgroundColor: "#284055",
                    headerColor: "#ffffff",
                    errorColor: "",
                    successColor: "",
                    card: {
                      padding: "",
                      backgroundColor: "",
                    },
                  },
                  data: {
                    orderId: orderCurrent._id,
                    token: tranId.body.txnToken,
                    tokenType: "TXN_TOKEN",
                    amount: orderCurrent.GrandTotal /* update amount */,
                  },
                  payMode: {
                    labels: {},
                    filter: {
                      exclude: [],
                    },
                    order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
                  },
                  website: "WEBSTAGING",
                  flow: "DEFAULT",
                  merchant: {
                    mid: "SHANTI03800179145316",
                    redirect: false,
                  },
                  handler: {
                    transactionStatus: function transactionStatus(
                      paymentStatus
                    ) {
                      setPaymentStatus(paymentStatus);
                      window.Paytm.CheckoutJS.close();
                      if (paymentStatus.STATUS === "TXN_SUCCESS") {
                        const orderPaymentForm = {
                          orderid: orderCurrent._id,
                          PaymentStatus: paymentStatus.STATUS,
                          CurrentStatus: "Paid",
                          TxnId: paymentStatus.TXNID,
                          PaymentMode: "PayTM",
                          GrandTotal: 0,
                        };

                        dispatch(updateOrder(orderPaymentForm));

                        navigate("/payment-success");
                      } else if (paymentStatus.STATUS === "TXN_FAILURE") {
                        const orderPaymentForm = {
                          orderid: orderCurrent._id,
                          PaymentStatus: "",
                          CurrentStatus: "Failed",
                          TxnId: "NA",
                        };

                        dispatch(updateOrder(orderPaymentForm));
                        toast.error("PAYMENT FAILED", {
                          position: "top-center",
                          autoClose: 500,
                        });
                        navigate("/payment-failed");
                      } else if (paymentStatus.STATUS === "PENDING") {
                        const orderPaymentForm = {
                          orderid: orderCurrent._id,
                          PaymentStatus: paymentStatus.STATUS,
                          CurrentStatus: "Pending",
                          TxnId: paymentStatus.TXNID,
                          PaymentMode: "PayTM",
                        };

                        dispatch(updateOrder(orderPaymentForm));
                        navigate("/payment-pending");
                      }
                    },
                    notifyMerchant: function notifyMerchant(eventName, data) {
                      // const orderPaymentForm = {
                      //   orderid: orderCurrent._id,
                      //   PaymentStatus: "Failed",
                      //   CurrentStatus: "Failed",
                      //   TxnId: "NA",
                      // };
                      // dispatch(updateOrder(orderPaymentForm));
                      navigate("/payment-failed");
                    },
                  },
                };
                if (window.Paytm && window.Paytm.CheckoutJS) {
                  window.Paytm.CheckoutJS.init(config)
                    .then(function onSuccess() {
                      window.Paytm.CheckoutJS.invoke();
                    })
                    .catch(function onError(error) {
                      console.log("error => ", error);
                    });
                }
                const sendOptthowwp = async () => {
                  // const name = name;
                  const mobile = loginData.Mobile;
                  let data = JSON.stringify({
                    countryCode: "+91",
                    phoneNumber: mobile,
                    callbackData: "some text here",
                    type: "Template",
                    template: {
                      name: "order_placed_prepaid",
                      languageCode: "en",
                      bodyValues: [name],
                    },
                  });

                  let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: "https://api.interakt.ai/v1/public/message/",
                    headers: {
                      Authorization:
                        "Basic djlWdldPY1habHlBbEtoZkFFUl8zVWdQTDAycmJqWl9BWHd4dDF4eHVjMDo=",
                      "Content-Type": "application/json",
                    },
                    data: data,
                  };

                  axios
                    .request(config)
                    .then((response) => {
                      // console.log(JSON.stringify(response.data));
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                };
                sendOptthowwp();
              } else {
                toast.error("ORDER NOT PLACED SUCCESSFULL", {
                  position: "top-center",
                  autoClose: 2000,
                });
                setbtnLoading(false);
              }
            }
          } else {
            toast.error("Please Add Address", {
              position: "top-center",
              autoClose: 2000,
            });
          }
        }
      } else {
        alert(resp?.data?.orderStatus?.statusText);
        navigate("/");
      }
    }
  };

  return (
    <>
      <div className="col-lg-4 p-2">
        <div className="right-side-summery-box">
          <div className="summery-box-2">
            <div className="summery-header">
              <h3>Order Summery</h3>
            </div>
            <ul className="summery-contain">
              {CartItems.map((cart, index) => (
                <li key={index}>
                  <img
                    src={cart.ImgUrl}
                    className="img-fluid blur-up lazyloaded checkout-image"
                    alt
                  />
                  <h4>
                    {cart.ItemName}{" "}
                    <span>
                      {Number(cart.Qty)} X ₹{Number(cart.Price)}
                    </span>
                  </h4>
                  <h4 className="price"> ₹{Number(cart.TotalAmount)}</h4>
                </li>
              ))}
            </ul>
            <ul className="summery-total">
              <li>
                <h4>Total MRP</h4>
                <h4 className="price">
                  {" "}
                  ₹{Number(cartamountDetails.cartTotalMrp)}
                </h4>
              </li>
              <li>
                <h4>Sub-Total</h4>
                <h4 className="price"> ₹{cartamountDetails.cartTotalAmount}</h4>
              </li>
              <li>
                <h4>Shipping</h4>
                <h4 className="price"> ₹{cartamountDetails.logisticsAmount}</h4>
              </li>
              {/* <li>
                <h4>Tax</h4>
                <h4 className="price"> ₹0</h4>
              </li> */}

              <li>
                <h4>Coupon/Code</h4>
                <h4 className="price">- ₹{Number(cartCouponDiscount)}</h4>
              </li>
              <li>
                <h4>Wallet Used</h4>
                <h4 className="price"> - ₹{Number(usedWalletAmount)}</h4>
              </li>

              <li className="list-total">
                <h4>Grand Total (INR)</h4>
                <h4 className="price">
                  {" "}
                  {Number(cartamountDetails.totalAmount) -
                    Number(usedWalletAmount)}
                </h4>
              </li>
            </ul>
          </div>
          {/* <div className="checkout-offer">
            <div className="offer-title">
              <div className="offer-icon">
                <img
                  src="https://themes.pixelstrap.com/fastkart/assets/images/inner-page/offer.svg"
                  className="img-fluid"
                  alt
                />
              </div>
              <div className="offer-name">
                <h6>Available Offers</h6>
              </div>
            </div>
            <ul className="offer-detail">
              <li>
                <p>
                  Combo: BB Royal Almond/Badam Californian, Extra Bold 100 gm...
                </p>
              </li>
              <li>
                <p>
                  combo: Royal Cashew Californian, Extra Bold 100 gm + BB Royal
                  Honey 500 gm
                </p>
              </li>
            </ul>
          </div> */}
          {currentAddress !== "" ? (
            <>
              {btnLoading === true ? (
                <>
                  <button
                    className="btn text-white btn-md w-100 mt-4 fw-bold"
                    style={{ background: "#b3b3b3" }}
                  >
                    Place Order
                  </button>

                  <div className="d-flex justify-content-center">
                    <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                      Order is processing
                    </h4>
                    <BeatLoader
                      color={"#36d7b7"}
                      loading={btnLoading}
                      size={13}
                      className="mt-2 mx-2"
                    />
                  </div>
                </>
              ) : (
                <>
                  <button
                    className="btn theme-bg-color text-white btn-md w-100 mt-4 fw-bold"
                    onClick={
                      paymentType === "CashOnDelivery"
                        ? cashOnDeliveryHandleSubmit
                        : payTmPayHandleSubmit
                    }
                  >
                    Place Order
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              <button
                className="btn theme-bg-color text-white btn-md w-100 mt-4 fw-bold"
                style={{ opacity: "0.5" }}
              >
                Add Your Address
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RightSideCheckOut;
