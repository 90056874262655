import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import moment from "moment/moment";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { getOrderbyClId, updateOrder } from "../redux/order/OrderSlice";
import {
  clientUpdate,
  getClientByMob,
  walletTransitionPost,
} from "../redux/athentication/Athentication";

const OrderTracking = () => {
  const { clientOrder } = useSelector((state) => state.Order);
  const { loginData, clientid } = useSelector((store) => store.Athentication);

  const params = useParams();
  const dispatch = useDispatch();

  const [orderProceDate, setOrderProceDate] = useState("");
  const [orderinfo, setOrderinfo] = useState("");
  const [orderId, setOrderId] = useState("");
  const [deeliveryInfo, setDeeliveryInfo] = useState("");

  useEffect(() => {
    const orderUrl = params.orderId;
    const fetchData = async () => {
      try {
        const url = `${Baseurl}/api/v1/order/singleorderbyclient/${orderUrl}`;
        const orderFetch = await axios.get(url);
        try {
          if (orderFetch.data.success) {
            let singleOrderData = orderFetch.data.order;
            const orderId = params.orderId.slice(18);
            setOrderinfo(singleOrderData);
            setOrderProceDate(singleOrderData.OrderprocessDate);
            setDeeliveryInfo(singleOrderData.Delivery);
            setOrderId(orderId);
          }
        } catch (error) {}
      } catch (error) {}
    };

    fetchData();
  }, [params.orderId]);

  const orderCancelClick = async (e, selectOrder) => {
    var date = new Date();

    date.setDate(date.getDate());
    if (selectOrder.hasOwnProperty("OrderprocessDate")) {
    } else {
      var OrderprocessDate = {};
    }

    if (selectOrder.hasOwnProperty("OrderprocessDate")) {
      var OrderprocessDate = {
        OrderprocessingDate: selectOrder.OrderprocessDate.OrderprocessingDate,
        OrderDispatchedDate: selectOrder.OrderprocessDate.OrderDispatchedDate,
        OutfordeliveryDate: selectOrder.OrderprocessDate.OutfordeliveryDate,
        OrderDeliveredDate: selectOrder.OrderprocessDate.OrderDeliveredDate,
        OrderCancelledDate: date,
      };
    } else {
      var OrderprocessDate = {};
    }

    const formData = {
      Status: 0,
      StatusText: "Order Cancelled",
      orderid: selectOrder._id,
      remark: {
        OrderCancelRemark: "Your delivery is cancelled",
      },
      OrderprocessDate: OrderprocessDate,
    };
    const data = await dispatch(updateOrder(formData));

    const getClientForm = {
      Mobile: selectOrder.Mobile,
    };

    const getOrderClient = await dispatch(getClientByMob(getClientForm));

    let getclient = getOrderClient.payload.client;
    if (selectOrder.CurrentStatus === "Not Paid") {
      if (selectOrder.wallet > 0) {
        const formDta = {
          WalletBalance:
            Number(getclient.WalletBalance) + Number(selectOrder.wallet),
          claintid: selectOrder.ClientId,
        };
        dispatch(clientUpdate(formDta));

        const walletForm = {
          ClientId: getclient._id,
          previousAmount: getclient.WalletBalance,
          availableAmount:
            Number(getclient.WalletBalance) + Number(selectOrder.wallet),
          transactionAmount: Number(selectOrder.wallet),
          orderId: selectOrder._id,
          Type: "Wallet Credit on Order Cancel",
          ClientName: getclient.Name,
          Mobile: getclient.Mobile,
        };
        await dispatch(walletTransitionPost(walletForm));
      }
    } else if (selectOrder.CurrentStatus === "Paid") {
      const formDta = {
        WalletBalance:
          Number(getclient.WalletBalance) + Number(selectOrder.TotalAmount),
        claintid: selectOrder.ClientId,
      };
      dispatch(clientUpdate(formDta));
      const walletForm = {
        ClientId: getclient._id,
        previousAmount: getclient.WalletBalance,
        availableAmount:
          Number(getclient.WalletBalance) + Number(selectOrder.wallet),
        transactionAmount: Number(selectOrder.wallet),
        orderId: selectOrder._id,
        Type: "Wallet Credit on Order Cancel",
        ClientName: getclient.Name,
        Mobile: getclient.Mobile,
      };
      await dispatch(walletTransitionPost(walletForm));
    } else if (selectOrder.CurrentStatus === "Partial Payment") {
      const formDta = {
        WalletBalance:
          Number(getclient.WalletBalance) +
          Number(selectOrder.TotalAmount) -
          Number(selectOrder.GrandTotal),
        claintid: selectOrder.ClientId,
      };
      dispatch(clientUpdate(formDta));
      const walletForm = {
        ClientId: getclient._id,
        previousAmount: getclient.WalletBalance,
        availableAmount:
          Number(getclient.WalletBalance) +
          Number(selectOrder.TotalAmount) -
          Number(selectOrder.GrandTotal),
        transactionAmount: Number(selectOrder.wallet),
        orderId: selectOrder._id,
        Type: "Wallet Credit on Partial Order Cancel",
        ClientName: getclient.Name,
        Mobile: getclient.Mobile,
      };
      await dispatch(walletTransitionPost(walletForm));
    }
    dispatch(getOrderbyClId(clientid));
    const fetchData = async () => {
      try {
        const url = `${Baseurl}/api/v1/order/singleorderbyclient/${selectOrder._id}`;
        const orderFetch = await axios.get(url);
        try {
          if (orderFetch.data.success) {
            let singleOrderData = orderFetch.data.order;
            const orderId = params.orderId.slice(18);
            setOrderinfo(singleOrderData);
            setOrderProceDate(singleOrderData.OrderprocessDate);
            setDeeliveryInfo(singleOrderData.Delivery);
            setOrderId(orderId);
          }
        } catch (error) {}
      } catch (error) {}
    };

    fetchData();
  };

  return (
    <>
      <section className="breadscrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain">
                <h2>Order Tracking</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="fa-solid fa-house" />
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Order Tracking
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="order-detail">
        <div className="container-fluid-lg">
          <div className="row g-sm-4 g-3">
            <div className="col-xxl-3 col-xl-4 col-lg-6">
              <div className="order-image">
                <Swiper
                  // grabCursor={true}
                  navigation={false}
                  pagination={false}
                  mousewheel={false}
                  keyboard={true}
                  modules={[
                    Navigation,
                    Pagination,
                    Mousewheel,
                    Keyboard,
                    Autoplay,
                  ]}
                  loop={true}
                  autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  breakpoints={{
                    360: {
                      slidesPerView: 1,
                      spaceBetween: 5,
                    },
                    460: {
                      slidesPerView: 1,
                      spaceBetween: 5,
                    },
                    720: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    1080: {
                      slidesPerView: 1,
                      spaceBetween: 7,
                    },
                  }}
                  // className="mySwiper"
                >
                  {orderinfo.hasOwnProperty("_id") ? (
                    <>
                      {orderinfo.OrderProducts.map((image, index) => (
                        <SwiperSlide>
                          <img
                            src={image.ImgUrl}
                            className="img-fluid blur-up lazyload"
                            alt="img"
                            key={index}
                          />
                        </SwiperSlide>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Swiper>
              </div>
            </div>
            <div className="col-xxl-9 col-xl-8 col-lg-6">
              <div className="row g-sm-4 g-3">
                <div className="col-xl-4 col-sm-6 p-1">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <i data-feather="package" className="text-content" />
                    </div>
                    <div className="order-details-name">
                      <h5 className="text-content">Tracking Code</h5>
                      {orderinfo.hasOwnProperty("_id") ? (
                        <>
                          <h2
                            className="theme-color"
                            style={{ textTransform: "uppercase" }}
                          >
                            {orderId}
                          </h2>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 p-1">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <i data-feather="truck" className="text-content" />
                    </div>
                    <div className="order-details-name">
                      <h5 className="text-content">Delivery Man Details</h5>
                      {/* <img
                        src="https://themes.pixelstrap.com/fastkart/assets/images/inner-page/brand-name.svg"
                        className="img-fluid blur-up lazyload"
                        alt
                      /> */}
                      {orderinfo.hasOwnProperty("Delivery") ? (
                        <>
                          <h4>{deeliveryInfo.DName}</h4>
                          <h4>{deeliveryInfo.DMobile}</h4>
                        </>
                      ) : (
                        <>Not Assign</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 p-1">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <i className="text-content" data-feather="info" />
                    </div>
                    <div className="order-details-name">
                      <h5 className="text-content">Order Status</h5>
                      <h4>{orderinfo.StatusText}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 p-1">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <i className="text-content" data-feather="crosshair" />
                    </div>
                    <div className="order-details-name">
                      <h5 className="text-content">From</h5>
                      <h4>SHANTI NATURAL FARMS, BABNIDIH KHAIRBANI,Jamshedpur</h4>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 p-1">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <i className="text-content" data-feather="map-pin" />
                    </div>
                    <div className="order-details-name">
                      <h5 className="text-content">Destination</h5>

                      {orderinfo.hasOwnProperty("_id") ? (
                        <>
                          <h4>{orderinfo.Address.slice(0, 24)}</h4>
                          <h4>{orderinfo.Address.slice(24, 48)}</h4>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 p-1">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <i className="text-content" data-feather="calendar" />
                    </div>
                    <div className="order-details-name">
                      <h5 className="text-content">Estimated Time</h5>
                      {orderinfo.hasOwnProperty("_id") ? (
                        <>
                          {" "}
                          <h4>
                            {" "}
                            {moment(orderinfo.ExpectedDelDate).format(
                              "MMM DD YY"
                            )}
                          </h4>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 p-1">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <i className="text-content" data-feather="calendar" />
                    </div>
                    <div
                      className="order-details-name"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => orderCancelClick(e, orderinfo)}
                    >
                      {/* <h5 className="text-content">Order Cancel</h5> */}
                      {orderinfo.Status === 1 ? (
                        <>
                          <h4
                            style={{ color: "red" }}
                            onClick={(e) => orderCancelClick(e, orderinfo)}
                          >
                            Order Cancel
                          </h4>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 overflow-hidden">
                  {orderinfo.Status === 0 ? (
                    <>
                      <ol className="progtrckr">
                        <li
                          className={
                            orderinfo.Status === 0
                              ? "progtrckr-done"
                              : "progtrckr-todo"
                          }
                        >
                          <h5>Booked Order </h5>
                          {orderinfo.Status === 0 ? (
                            <>
                              <h6>
                                {moment(orderProceDate.OrderprocessDate).format(
                                  "MMM DD YY HH:mm A"
                                )}
                              </h6>
                            </>
                          ) : (
                            <>
                              <h6>Processing</h6>
                            </>
                          )}
                        </li>
                        <li
                          className={
                            orderinfo.Status === 0
                              ? "progtrckr-done"
                              : "progtrckr-todo"
                          }
                        >
                          <h5>Cancel Order</h5>
                          {orderinfo.Status === 0 ? (
                            <>
                              <h6>
                                {moment(
                                  orderProceDate.OrderCancelledDate
                                ).format("MMM DD YY HH:mm A")}
                              </h6>
                            </>
                          ) : (
                            <>
                              {/* {orderinfo.Status >= 1 ? (
                                <>
                                  <h6>Processing</h6>
                                </>
                              ) : (
                                <>
                                  <h6>Pending</h6>
                                </>
                              )} */}
                            </>
                          )}
                        </li>
                      </ol>
                    </>
                  ) : (
                    <>
                      <ol className="progtrckr">
                        <li
                          className={
                            orderinfo.Status >= 1
                              ? "progtrckr-done"
                              : "progtrckr-todo"
                          }
                        >
                          <h5>Booked Order </h5>
                          {orderinfo.Status >= 1 ? (
                            <>
                              <h6>
                                {moment(orderProceDate.OrderprocessDate).format(
                                  "MMM DD YY HH:mm A"
                                )}
                              </h6>
                            </>
                          ) : (
                            <>
                              <h6>Processing</h6>
                            </>
                          )}
                        </li>
                        <li
                          className={
                            orderinfo.Status >= 2
                              ? "progtrckr-done"
                              : "progtrckr-todo"
                          }
                        >
                          <h5>Processing Order</h5>
                          {orderinfo.Status >= 2 ? (
                            <>
                              <h6>
                                {moment(
                                  orderProceDate.OrderDispatchedDate
                                ).format("MMM DD YY HH:mm A")}
                              </h6>
                            </>
                          ) : (
                            <>
                              {orderinfo.Status >= 1 ? (
                                <>
                                  <h6>Processing</h6>
                                </>
                              ) : (
                                <>
                                  <h6>Pending</h6>
                                </>
                              )}
                            </>
                          )}
                        </li>
                        <li
                          className={
                            orderinfo.Status >= 3
                              ? "progtrckr-done"
                              : "progtrckr-todo"
                          }
                        >
                          <h5>Dispatched Order</h5>
                          {orderinfo.Status >= 3 ? (
                            <>
                              <h6>
                                {moment(
                                  orderProceDate.OrderDispatchedDate
                                ).format("MMM DD YY HH:mm A")}
                              </h6>
                            </>
                          ) : (
                            <>
                              {orderinfo.Status >= 2 ? (
                                <>
                                  <h6>Processing</h6>
                                </>
                              ) : (
                                <>
                                  <h6>Pending</h6>
                                </>
                              )}
                            </>
                          )}
                        </li>
                        <li
                          className={
                            orderinfo.Status >= 4
                              ? "progtrckr-done"
                              : "progtrckr-todo"
                          }
                        >
                          <h5>Out For Delivery</h5>
                          {orderinfo.Status >= 4 ? (
                            <>
                              <h6>
                                {moment(
                                  orderProceDate.OrderDispatchedDate
                                ).format("MMM DD YY HH:mm A")}
                              </h6>
                            </>
                          ) : (
                            <>
                              {orderinfo.Status >= 3 ? (
                                <>
                                  <h6>Processing</h6>
                                </>
                              ) : (
                                <>
                                  <h6>Pending</h6>
                                </>
                              )}
                            </>
                          )}
                        </li>
                        <li
                          className={
                            orderinfo.Status >= 5
                              ? "progtrckr-done"
                              : "progtrckr-todo"
                          }
                        >
                          <h5>Delivered Order</h5>
                          {orderinfo.Status >= 5 ? (
                            <>
                              <h6>
                                {moment(
                                  orderProceDate.OrderDispatchedDate
                                ).format("MMM DD YY HH:mm A")}
                              </h6>
                            </>
                          ) : (
                            <>
                              {orderinfo.Status >= 4 ? (
                                <>
                                  <h6>Processing</h6>
                                </>
                              ) : (
                                <>
                                  <h6>Pending</h6>
                                </>
                              )}
                            </>
                          )}
                        </li>
                      </ol>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="order-table-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table order-tab-table">
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Item Name</th>
                      <th>PackSize</th>
                      <th>Quentity</th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderinfo.hasOwnProperty("_id") ? (
                      <>
                        {orderinfo.OrderProducts.map((order, index) => (
                          <tr key={index}>
                            {/* <td>
                              <img
                                src={order.ImgUrl}
                                style={{ height: "50%" }}
                              />
                            </td> */}
                            <td>{index + 1} </td>
                            <td>{order.ProductName} </td>
                            <td>{order.PackSize} </td>
                            <td>{order.Qty}</td>
                            <td>{order.TotalAmount}</td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderTracking;
